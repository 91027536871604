<template>
    <nav
        class="flex justify-between z-[31] h-full"
        :class="expandNav ? 'fixed bg-[var(--grey-900)] w-full h-screen' : ''"
    >
        <div class="flex flex-col p-[25px] h-[86px]">
            <InertiaLink href="/players/me/rounds">
                <SwingULogo />
            </InertiaLink>
        </div>
        <div
            class="cursor-pointer h-[86px] py-[35px] px-[25px]"
            @click="expandNav = !expandNav"
        >
            <HamburgerIcon v-if="!expandNav" :fill="'white'" class="h-[16px]" />
            <CloseIcon v-else :fill="'white'" class="h-[16px] fill-white" />
        </div>
        <div
            :class="
                expandNav
                    ? 'fixed bottom-0 left-0 top-[86px] right-0 block bg-visible bg-white text-lg font-semibold text-[var(--grey-800)] z-[31]'
                    : 'hidden'
            "
        >
            <ul
                id="mobileNavList"
                class="flex flex-col items-start fixed left-0 top-[86px] w-full"
            >
                <li class="border-b border-[var(--grey-200)] w-full">
                    <a
                        class="h-full block w-full relative"
                        href="/players/me/rounds"
                    >
                        <div
                            class="margin-0 absolute top-1/2 -translate-y-1/2 pl-[25px]"
                        >
                            Rounds
                        </div>
                    </a>
                </li>
                <li class="border-b border-[var(--grey-200)] w-full">
                    <a
                        class="h-full block w-full relative"
                        href="/players/me"
                    >
                        <div
                            class="margin-0 absolute top-1/2 -translate-y-1/2 pl-[25px]"
                        >
                            Advanced Stats
                        </div>
                    </a>
                </li>
                <li class="border-b border-[var(--grey-200)] w-full">
                    <a
                        class="h-full block w-full relative"
                        href="/players/me/stats/basic"
                    >
                        <div
                            class="margin-0 absolute top-1/2 -translate-y-1/2 pl-[25px]"
                        >
                            Basic Stats
                        </div>
                    </a>
                </li>
                <!-- Update to this Clubs link when ready to port in v2 clubs views. -->
                <li class="border-b border-[var(--grey-200)] w-full">
                    <a 
                        class="h-full block w-full relative" 
                        href="/players/me/clubs"
                    >
                        <div
                            class="margin-0 absolute top-1/2 -translate-y-1/2 pl-[25px]"
                        >
                            Clubs
                        </div>
                    </a>
                </li>
                <li class="border-b border-[var(--grey-200)] w-full">
                    <a
                        class="h-full block w-full relative"
                        href="/account/info"
                    >
                        <div
                            class="margin-0 absolute top-1/2 -translate-y-1/2 pl-[25px]"
                        >
                            Account Info
                        </div>
                    </a>
                </li>
                <li class="border-b border-[var(--grey-200)] w-full">
                    <a
                        class="h-full block w-full relative"
                        href="/account/settings"
                    >
                        <div
                            class="margin-0 absolute top-1/2 -translate-y-1/2 pl-[25px]"
                        >
                            Settings
                        </div>
                    </a>
                </li>
                <li class="border-b border-[var(--grey-200)] w-full">
                    <a
                        class="h-full block w-full relative"
                        href="/account/subscription"
                    >
                        <div
                            class="margin-0 absolute top-1/2 -translate-y-1/2 pl-[25px]"
                        >
                            Subscription
                        </div>
                    </a>
                </li>
                <!-- <li class="border-b border-[var(--grey-200)] w-full">
                    <a
                        class="text-[var(--medium-primary)] h-full block w-full relative"
                        href="/round"
                    >
                        <div
                            class="margin-0 absolute top-1/2 -translate-y-1/2 pl-[25px]"
                        >
                            Post Score
                        </div>
                    </a>
                </li> -->
                <li class="border-b border-[var(--grey-200)] w-full">
                    <a
                        :href="$page.props.logoutRoute"
                        class="text-[var(--medium-warning)] h-full block w-full relative"
                    >
                        <div
                            class="margin-0 absolute top-1/2 -translate-y-1/2 pl-[25px]"
                        >
                            Log Out
                        </div>
                    </a>
                </li>
            </ul>
        </div>
    </nav>
</template>

<script setup>
import SwingULogo from "@svg/swingu-white-logo.vue";
import HamburgerIcon from "@svg/hamburger-menu.vue";
import CloseIcon from "@svg/close-icon.vue";
</script>

<script>
export default {
    data() {
        return {
            expandNav: false,
        };
    },
};
</script>

<style scoped>
nav {
    @apply text-base font-medium text-white leading-none;
}
#mobileNavList {
    height: calc(100vh - 86px);
}

#mobileNavList > li {
    /* height: calc((100vh - 86px) / 9); Uncomment when post score is ready*/ 
    height: calc((100vh - 86px) / 8);
}
</style>
